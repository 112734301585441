.copy-button {
    /*border: 1px solid #d5d5d5;*/
    /*width: 10%;*/
    height: 36px;
    width: 36px;
    /*size: auto;*/
    /*font-size: 13px;*/
    user-select: none;

    vertical-align: top;
    /*top: -10px;*/

    /*white-space: nowrap;*/
    /*vertical-align: middle;*/
    /*margin-top: -3px;*/
    /*position: relative;*/
    /*top: 3px;*/
}

.clippy {
    margin-top: -3px;
    position: relative;
    top: 3px;
    /*width: 25px;*/
}

.clipboard-text {
    font-size: large;
    height: 30px;
    width: 80%;
}
