.form-body {
    font-size: large;
}

.error-popup {

}

.total-owed {
    margin-top: 10px;
    font-size: xx-large;
}

input[type="text"] {

}

.submit-button {
    background-color: red;
    border-color: #bb0000;
    color: white;
    /*min-height: 40px;*/
    /*min-width: 100px;*/
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    padding: 5px 10px;

}

.submit-button:hover {
    background-color: #cc0000;
    color: #ffffff;
}

.submit-button:disabled,
.submit-button[disabled] {
    background-color: #cccccc;
    color: #666666;
    border-color: #666666;
}


.form-field-grid {
    margin-left: 25px;
    margin-right: 25px;
    display: grid;
    /*grid-template-columns: [first] calc(min(350px, 35%)) [line2] auto [line3] 150px;*/
    grid-template-columns: [first] calc(min(350px, 35%)) [line2] auto [line3] calc(max(150px, 30%));
    /*grid-template-rows: [row1-start] 25% [row1-end] 100px [third-line] auto [last-line];*/
}


.label, .field, .errorbar {
    margin: 3px 5px;
}

.label, .field {
    text-align: left;
}

.field input {
    width: 100%;
}

.hidden-field {
    visibility: hidden;
}

.field input.short-input {
    width: unset;
}

input[type=checkbox] {
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
}

input[type=radio] {
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
}

.errorbar {
    color: #bb0000;
}

.thank-you {

}

.final-instructions {

}

.details-table {
    font-size: larger;
    /*margin: auto;*/
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
}

.details-type {
    text-align: left;
}

.details-content {
    font-weight: bold;
}

.banklist {

}

.form-body p {
    margin-left: 30px;
    margin-right: 30px;
}

.banklist p {
    margin-top: 2px;
    margin-bottom: 0;
}