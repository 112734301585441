.App {
    text-align: center;
}

.banner {
    height: 160px;
    pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-header {*/
/*    animation: App-header-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App-header {
    background-color: #E02224;
    min-height: 161px;

    background-image: url("../img/cpcheader2022.png");
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@media only screen and (max-width: 750px) {
    /*  For smaller screens, we crudely chop off the French part. */
    .App-header {
        background-image: url("../img/cpcheader2022-small.png");
        background-size: contain;
    }
}

/*.App-link {*/
/*    color: #61dafb;*/
/*}*/

.form-body {

}